<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-title>Developer Stats</v-card-title>
          <v-card-text>
            <apexchart v-if="!loading" type="bar" :options="chartOptionsComputed" :series="seriesComputed"></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Dashboard",

  computed: {
    getTalentDataByCookieCampaign() {
      // eslint-disable-next-line no-prototype-builtins
      return this.talentData.filter(t => t.json.hasOwnProperty("cookie"))
    },

    getTalentDataCount() {
      let obj = {
        totalTalentSubmissionsCount: this.talentData.length,
        totalTalentSubmissionsWithCookieData: this.getTalentDataByCookieCampaign.length,
      }

      Object.assign(obj, this.cookieUtmCampaignGroupBy)

      return obj
    },

    getTalentDataByCookieCampaignReplacePlus() {
      return this.getTalentDataByCookieCampaign.map(t => {
        t.json.cookie.utm_campaign = t.json.cookie?.utm_campaign?.replaceAll(/%20/g, "+")
        t.json.cookie.utm_medium = t.json.cookie?.utm_medium?.replaceAll(/%20/g, "+")
        t.json.cookie.utm_source = t.json.cookie?.utm_source?.replaceAll(/%20/g, "+")
        return t
      })
    },

    cookieUtmCampaignGroupBy() {
      return this.getTalentDataByCookieCampaignReplacePlus
          .map(t => t.json.cookie.utm_campaign)
          .reduce((r,c) => (r[c] = (r[c] || 0) + 1, r), {})
    },
    cookieUtmMediumGroupBy() {
      return this.getTalentDataByCookieCampaignReplacePlus
          .map(t => t.json.cookie.utm_medium)
          .reduce((r,c) => (r[c] = (r[c] || 0) + 1, r), {})
    },
    cookieUtmSourceGroupBy() {
      return this.getTalentDataByCookieCampaignReplacePlus
          .map(t => t.json.cookie.utm_source)
          .reduce((r,c) => (r[c] = (r[c] || 0) + 1, r), {})
    },

    chartOptionsComputed() {
      return {
        xaxis: {
          chart: {
            id: "piechart-dev-stats-1"
          },
          categories: Object.keys(this.getTalentDataCount)
        }
      }
    },

    seriesComputed() {
      return [{
        name: "Campaigns",
        data: Object.values(this.getTalentDataCount)
      }]
    }
  },

  mounted() {
    this.getTalentSubmissionData()
    this.getSheetsData()
  },

  data() {
    return {
      talentData: [],
      // Test data
      chartOptions: {
        xaxis: {
          chart: {
            id: "piechart-dev-stats-1"
          },
          categories: [
              2000,
              2001,
              2002,
              2003,
              2004
          ]
        }
      },
      series: [{
        name: "Test",
        data: [10, 20, 30, 40, 50]
      }],
      loading: true,
    }
  },

  methods: {
    getTalentSubmissionData() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/talent-statistics`, this.$store.state.headers)
          .then(function (response) {
            self.talentData = response.data
            self.loading = false
          });
    },

    getSheetsData() {
      axios.get(`${this.$store.state.apiUrl}/api/sheets`, this.$store.state.headers)
          .then(function (response) {
            console.log(response)
          });
    },
  }
}
</script>

<style scoped>

</style>
